import { React, useState, useEffect } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';
import { fetchDevices, deleteDevice } from '../devices/DeviceAPI';
import LinkDeviceModal from './LinkDeviceModal';


const HeaderDevices = ({ isAuthenticated }) => {

  const [devices, setDevices] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const loadDevices = () => {
    fetchDevices().then((data) => {
      if (data) {
        setDevices(data);
      }
      setIsLoaded(true);
    });
  }

  useEffect(() => {
    loadDevices();
  }, []);

  const handleDeleteDevice = (id) => {
    deleteDevice(id)
    .then(() => {
      setDevices(devices.filter(device => device._id !== id));
    })
    .catch(error => {
      console.error('Failed to delete device:', error);
    });
  };

  const handleLinkDevice = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
    setTimeout(() => {
      // Race condition, the read doesn't pick up rhe read, despite attempts to synchronize.
      loadDevices();
    }, 3000);
  }

  return (
    <>
      {isLoaded && isAuthenticated ? (
        <>
          {devices.length == 0 ? (
            <Button variant="link" onClick={handleLinkDevice}>
              Link a Device
            </Button>
          ) : (
            <Dropdown align="end">
              <Dropdown.Toggle variant="link" id="dropdown-devices">
                {/* Circular Profile Image */}
                <Image
                  src="/images/devices/edge-1050.jpg"
                  roundedCircle
                  width="30"
                  height="30"
                  alt="Device"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {devices.map(device => (
                  <Dropdown.Item key={device._id} className="d-flex">
                    <Button variant="link" onClick={() => handleDeleteDevice(device._id)} className="p-0">
                      <XCircle color="grey" size={20} />
                    </Button>
                    {/* Device Image and Name */}
                    <div className="d-flex">
                      <Image src={`images/devices/${device.deviceType}.jpg`} width="30" height="30" roundedCircle alt={device.deviceName} />
                      <span className="ms-2">{device.deviceName}</span>
                    </div>
                  </Dropdown.Item>
                ))}
                <Dropdown.Item>
                <div variant="link" onClick={handleLinkDevice}>
                  Link a Device
                </div>                  
            </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}


          <LinkDeviceModal
            showModal={showModal}
            handleModalClose={handleModalClose}
          />

        </>
      ) : (
        <span></span>
      )}

    </>

  );
};

export default HeaderDevices;
