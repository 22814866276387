import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import Header from './Header';  // Import your header component
import { AuthenticationManager } from '../authentication/AuthenticationManager';
import { fetchEnvironment } from '../env/Environment';
import { fetchUserProfile } from '../user-profile/UserProfileAPI';
import Map from '../map/Map.jsx';


const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [googleLoginUrl, setGoogleLoginUrl] = useState('');

  useEffect(() => {

    fetchEnvironment().then((data) => {
      if (data) {
        setGoogleLoginUrl(data.googleLoginUrl);
      }
    });

    if (AuthenticationManager.hasTokenOnQueryString()) {
      AuthenticationManager.storeTokenOnQueryString();
    }

    if (!AuthenticationManager.isAuthenticated()) {
      setIsAuthenticated(false);
      setIsLoaded(true);
      return;
    }

    fetchUserProfile().then((data) => {
      if (data) {
        setUser(data);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setIsLoaded(true);
    });



  }, []);

  const handleSignIn = () => {
    window.location.href = googleLoginUrl;


  };

  const handleSignOut = () => {
    AuthenticationManager.logOut();
    setIsAuthenticated(false);
  }

  return (
    <div>

      <Header
        user={user}
        isLoaded={isLoaded}
        isAuthenticated={isAuthenticated}
        handleSignIn={handleSignIn}
        handleSignOut={handleSignOut}
      />

      <Map />

      <div className="content">
        {/* Your other content goes here */}
      </div>

    </div>
  );
};

export default App;
