import {React, useEffect, useRef} from 'react';
import mapboxgl from 'mapbox-gl';  
import 'mapbox-gl/dist/mapbox-gl.css';  
import { fetchAllTalismans } from './Talisman';


const Map = ({ }) => {

    const mapRef = useRef()
    const mapContainerRef = useRef()

    useEffect(() => {
        // This still gets invoked twice for some reason...
        mapboxgl.accessToken = 'pk.eyJ1Ijoia3NoYWZmMDMiLCJhIjoiY2poMTZkdm81MDBybjJxcGIxMmI3eHdjcSJ9.ou0XqCZckeVfJ3S03y2hSA'
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/dark-v10', 
          center: [4.4802, 52.1636], 
          zoom: 12 
        });

        //new mapboxgl.Marker().setLngLat([4.4802, 52.1636]).addTo(mapRef.current);

        fetchAllTalismans().then((data) => {
          if (data) {
            data.forEach(function (talisman) {
                //let coords = [talisman.currentPosition.coordinates[1], talisman.currentPosition.coordinates[0]];
                new mapboxgl.Marker().setLngLat(talisman.currentPosition.coordinates).addTo(mapRef.current);
            }); 
          }       
        });
    
        return () => {
          mapRef.current.remove()
        }
      }, [])
    
      return (
        <>
          <div id='map-container' ref={mapContainerRef}/>
        </>
      )
};

export default Map;
