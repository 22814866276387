const ALL_TALISMANS_PATH = '/api/talisman/all';

export const fetchAllTalismans= async () => {
    try {
        const response = await fetch(ALL_TALISMANS_PATH, {
            method: 'GET'
        });

        // if (response.status === 401 || response.status === 404) {
        //     AuthenticationManager.logOut();
        //     throw new Error('Unauthenticated');
        // }

        if (!response.ok) {
            throw new Error('Error fetching talismans'); 
        }

        const data = await response.json();
        return data;  

    } catch (error) {
        console.log('Error:', error.message);
        return null;  
    }
};
