import React from 'react';
import { Navbar, Nav, Dropdown, Image, Container, Button } from 'react-bootstrap';
import HeaderDevices from './HeaderDevices';

const Header = ({ user, isLoaded, isAuthenticated, handleSignIn, handleSignOut, handleLinkDevice }) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        {/* You can replace 'MyApp' with your own title or logo */}
        <Navbar.Brand href="/">
          <img
            src="/images/logo-large.png"
            height="60"
            className="d-inline-block align-top"
            alt="App Logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>

          <Nav className="ms-auto">
            {isLoaded ? (
              isAuthenticated ? (
                <>
                <HeaderDevices
                  handleLinkDevice = {handleLinkDevice}
                  isAuthenticated = {isAuthenticated}
                ></HeaderDevices>
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" id="dropdown-profile">
                    {/* Profile image */}
                    <Image
                      src={user?.picture}
                      roundedCircle
                      width="40"
                      height="40"
                      alt={user?.displayName}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item href="/settings">Register Device</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </>
              ) : (
                <Button variant="outline-light" onClick={handleSignIn}>
                  Sign In
                </Button>
              )
            ) : (
              <span></span>  
            )}
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
